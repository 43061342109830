import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import GlobalStyle from '../styles/GlobalStyle';
import { Wrapper } from './ui';
import Header from './Header';
import Footer from './Footer';
import GeolocationQuestion from './GeolocationQuestion';
import { getSingleLocaleFields, buildUrl } from '../utils';

const Layout = ({
  seo,
  locale,
  slugLocales,
  noIndex,
  page,
  isContactFormLandingPage,
  children,
}) => {
  const { allDatoCmsSite } = useStaticQuery(graphql`
    query LayoutQuery {
      allDatoCmsSite {
        nodes {
          locale
          faviconMetaTags {
            ...GatsbyDatoCmsFaviconMetaTags
          }
        }
      }
    }
  `);

  const { faviconMetaTags } = getSingleLocaleFields(allDatoCmsSite, locale);

  return (
    <>
      <HelmetDatoCms seo={seo} favicon={faviconMetaTags}>
        <html lang={locale} />
        {slugLocales &&
          slugLocales.map(({ locale, value }, id) => (
            <link
              key={id}
              rel="alternate"
              hreflang={locale}
              href={`${process.env.GATSBY_SITE_URL}${buildUrl(value, {
                ...page,
                locale: locale,
              })}`}
            />
          ))}
        {noIndex && <meta name="robots" content="noindex, follow" />}
      </HelmetDatoCms>
      <GlobalStyle />
      <Wrapper>
        <Header locale={locale} />
        {children}
        <Footer locale={locale} />
        {!isContactFormLandingPage && <GeolocationQuestion locale={locale} />}
      </Wrapper>
    </>
  );
};

export default Layout;
