import React from 'react';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  brandColours,
  fontWeights,
  fontSize,
  standardTransition,
} from '../../styles';
import { Link } from './Link';

const commonButtonStyles = () => {
  return css`
    display: inline-block;
    padding: 15px 42px;
    color: ${({ alt }) =>
      alt ? brandColours.primary : brandColours.quaternary};
    font-weight: ${fontWeights.bold};
    ${fontSize(16)};
    line-height: 1.5;
    text-align: center;
    background-color: ${({ alt }) =>
      alt ? brandColours.quaternary : brandColours.primary};
    border-radius: 40px;
    transition: ${standardTransition('color')},
      ${standardTransition('background-color')};

    ${minBreakpointQuery.small`
      padding: 18px 46px;
    `}

    &:hover {
      color: ${({ alt }) =>
        alt ? brandColours.quaternary : brandColours.primary};
      background-color: ${({ alt }) =>
        alt ? brandColours.primary : brandColours.quaternary};
    }
  `;
};

const StyledButton = styled.button`
  ${({ alt }) => commonButtonStyles(alt)};
  border: none;
`;

const StyledButtonLinkInternal = styled(Link)`
  ${({ alt }) => commonButtonStyles(alt)};
`;

const StyledButtonLinkExternal = styled.a`
  ${({ alt }) => commonButtonStyles(alt)};
`;

export const Button = ({ children, alt, ...props }) => {
  const ButtonComponent = props.to
    ? StyledButtonLinkInternal
    : props.href
    ? StyledButtonLinkExternal
    : StyledButton;

  return (
    <ButtonComponent alt={alt} {...props}>
      {children}
    </ButtonComponent>
  );
};
